<template>
    <div class="page-container">
        <p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>   
        <el-table
            :data="tableData"
            border 
            style="width: 100%">
             <el-table-column
               prop="id"
               label="ID">
            </el-table-column>
             <el-table-column
               prop="content"
               label="留言内容">
            </el-table-column>
               <el-table-column
               prop="create_time"
               label="留言时间">
            </el-table-column>
             <el-table-column
               prop=""
               label="留言用户">
               <template slot-scope="scope">
                   <span v-if="scope.row.anonymity_status == 1">匿名</span>
                   <span v-else>{{scope.row.admin_name}}</span>
               </template>
            </el-table-column>
        </el-table>
        <pagination-view :table-data-total='total' @currPage='getCurrPage' @pageSize='getPageSize'></pagination-view> 
    </div>
</template>

<script>
export default {
    inject: ["reload"],
    data() {
        return {
            tableData: [],
            currentPage:1,
            pagesize:20,
            total:0
        }
    },
    created() {
        this.getData()
    },
    mounted() {

    },
    methods:{
         getData() {
            this.$api.post("SysMessage/index", {page:this.currentPage,limit:this.pagesize}, res => {
                if (res.code == 200) {
                    this.tableData = res.data.list;
                    this.total  = res.data.count
                }
            });
        },
        getCurrPage(val){   //获取当前页
            this.currentPage =  val
            this.getData()
        },
        getPageSize(val){   //获取每页条数
            this.pagesize =  val
             this.getData()
        },
    },
}
</script>
<style scoped>

</style>